function showPantry() {
    return {
        type: "pantry"
    }
}

function showMain() {
    return {
        type: "main"
    }
}

export default {showPantry, showMain};